import React, { useState, useRef } from "react";
import moment from "moment";
import axios from "axios";
import {
  Table,
  Button,
  Tooltip,
  Modal,
  message,
  Input,
  Space,
  Select,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import VerificationStatusTag from "../shared/VerificationStatusTag";
import UserBadgeTag from "../shared/UserBadgeTag";
import UserStatusTag from "../shared/UserStatusTag";
import TopupPackageTag from "../shared/TopupPackageTag";

const UserList = ({
  users,
  selectedRows,
  onSelectRows,
  loading,
  accountManager,
}) => {
  const tableColumns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.length - b.username.length,
        multiple: 1,
      },
      sortDirections: ["descend", "ascend"],
      render: (text, row) => <a href={`/user/${row._id}`}>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Credit",
      dataIndex: ["merchant", "credits"],
      key: "credit",
      sorter: {
        compare: (a, b) => a.merchant.credits - b.merchant.credits,
        multiple: 2,
      },
      render: (text, row) => text.toFixed(2),
    },
    {
      title: "Total Topup",
      dataIndex: ["merchant", "totalTopup"],
      key: "totalTopup",
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
      render: (text, row) => text,
    },
    {
      title: "Package",
      dataIndex: ["merchant", "topupPackage"],
      key: "topupPackage",
      render: (text) => (text ? <TopupPackageTag packageKey={text} /> : "-"),
    },
    {
      title: "Badge",
      dataIndex: "badge",
      key: "badge",
      render: (text, row) =>
        text ? <UserBadgeTag statusKey={text}>Verified</UserBadgeTag> : "-",
    },
    {
      title: "Status",
      dataIndex: "userStatus",
      key: "userStatus",
      render: (text, row) =>
        text ? <UserStatusTag statusKey={text}>Verified</UserStatusTag> : "-",
    },
    {
      title: "Total Usage",
      dataIndex: "totalUsage",
      key: "totalUsage",
      render: (text) => (text ? text.toFixed(2) : "-"),
      sorter: {
        compare: (a, b) => a.totalUsage - b.totalUsage,
        multiple: 2,
      },
    },
    {
      title: "Total Order",
      dataIndex: "totalNoOrder",
      key: "totalNoOrder",
      sorter: {
        compare: (a, b) => a.totalTopup - b.totalTopup,
        multiple: 3,
      },
    },
    {
      title: "Last Order Date",
      dataIndex: "lastOrderAt",
      key: "lastOrderAt",
      render: (text) => (text === null ? "-" : moment(text).fromNow()),
    },
    {
      title: "Last Topup Date",
      dataIndex: "lastTopupAt",
      key: "lastTopupAt",
      render: (text) => (text === null ? "-" : moment(text).fromNow()),
    },
    {
      title: "Last Active Date",
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      render: (text) => (text === null ? "-" : moment(text).fromNow()),
    },
    {
      title: "BRN",
      dataIndex: ["merchant", "BRN"],
      key: "brn",
    },
    {
      title: "Referral Code",
      dataIndex: "referralCode",
      key: "referralCode",
      render: (text) => (text ? text : "-"),
      sorter: (a, b) => {
        a = a.referralCode || "";
        b = b.referralCode || "";
        return a.localeCompare(b);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Locked Tier",
      dataIndex: "lockedTier",
      key: "lockedTier",
      render: (text, row) => (text ? "True" : "False"),
    },
    {
      title: "Email Verification",
      dataIndex: "emailVerifiedOn",
      render: (text, row) =>
        text !== null ? (
          <VerificationStatusTag
            statusKey="Verified"
            title={new Date(text).toLocaleString()}
          >
            Verified
          </VerificationStatusTag>
        ) : (
          <React.Fragment>
            <Tooltip placement="topLeft" title="Verify User Email">
              <Button
                onClick={() => verifyHandler(row)}
                type="primary"
                size="small"
              >
                {" "}
                Verify{" "}
              </Button>
            </Tooltip>
          </React.Fragment>
        )
    },
    {
      title: "Phone Verification",
      dataIndex: "phoneVerifiedOn",
      render: (text, row) => (
        <VerificationStatusTag
          statusKey={text ? "Verified" : "Unverified"}
          title={text ? new Date(text).toLocaleString() : ""}
        />
      )
    },
    {
      title: "Account Manager",
      dataIndex: "managerName",
      render: (text, row) => (
        <div>
          <Select
            defaultValue={text ? text : "Select Account Manager"}
            className="select-before"
            width="100px"
            onChange={(value) => addAccountManager(row, value)}
          >
            {accountManager.map((manager) => (
              <Select.Option key={manager.id} value={manager.id}>
                {manager.username}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
      width: 150,
    },
    {
      title: "Credit Tagging",
      dataIndex: "tagging",
      render: (text, row) => (
        <div>
          <Select
            defaultValue={text ? text : "Select User Tagging"}
            className="select-before"
            width="250px"
            onChange={(value) => updateUserTagging(row, value)}
          >
            <Select.Option value="Topup User">Topup User</Select.Option>
            <Select.Option value="Credit User">Credit User</Select.Option>
          </Select>
        </div>
      ),
    },
    {
      title:"Master User Id",
      dataIndex:"masterUserId",
      render: (text, row) => (text ? text : "-"),
    },
    {
      title:"Master Email",
      dataIndex:"masterEmail",
      render: (text, row) => (text ? text : "-"),
    },
  ];

  const updateUserTagging = async (row, value) => {
    console.log(row.id, value);
    const updateTagging = await axios
      .patch("/api/user/update-tagging", {
        userId: row._id,
        tagging: value,
      })
      .then((res) => {
        message.info(`${row.email} is succesfully assign as ${value} `);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const addAccountManager = async (row, value) => {
    console.log(row, value);
    const addManager = await axios
      .patch("/api/user/add-manager", {
        userId: row._id,
        managerId: value,
      })
      .then((res) => {
        message.info(`${row.email} is succesfully assign to ${value} `);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const verifyHandler = (user) => {
    const messageContent = (
      <p>
        Email: {user.email}
        <br />
      </p>
    );

    Modal.confirm({
      content: messageContent,
      title: `Verify this user's email?`,
      onOk() {
        axios
          .patch(`/api/users/${user._id}/manual-confirm`)
          .then((res) => {
            message.info(`Email verification for (${user.email}) is success.`);
          })
          .catch((error) => {
            message.error(error.message);
          });
      },
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  });

  return (
    <div className="table">
      <Table
        rowKey="id"
        rowSelection={{ selectedRows, onChange: onSelectRows }}
        className="table-section"
        columns={tableColumns}
        dataSource={users}
        pagination={false}
        scroll={{ x: true }}
        loading={loading}
      />
    </div>
  );
};

export default UserList;
